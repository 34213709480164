<template>
  <div>
    <HomeSwiper
      @search="search"
      src="/images/banner.jpg"
      placeholder-text="搜索产品"
      :keyword="query.keyword"
    ></HomeSwiper>

    <div class="box-card" v-loading="loading">
      <el-card>
        <template slot="header">
          <div style="display: flex; justify-content: center">
            <h2>展品与服务</h2>
          </div>
        </template>
        <el-row :gutter="20">
          <el-col
            :span="6"
            class="box-card"
            v-for="item in tableData"
            :key="item.id"
            :xs="24"
            :sm="8"
            :md="6"
            :lg="6"
            :xl="6"
          >
            <div class="good-box" @click="handleClick(item.id)">
              <div class="image-box">
                <img v-if="item.coverUrl" :src="item.coverUrl" class="image" />
                <div v-else class="txt">{{ item.name }}</div>
              </div>
              <div class="bottom">
                <div class="price">
                  <span class="num">￥{{ item.price }}</span>
                  <span class="norms">规格:{{ item.norms }}</span>
                </div>
                <div class="name">{{ item.name }}</div>
                <div class="tip">
                  品牌:{{ item.brand }} 产地/服务地区:{{ item.place }}
                </div>
                <div class="company" @click="handleCompany(item.companyId)">{{ item.companyName }}</div>
              </div>
            </div>
          </el-col>
        </el-row>

        <div class="page">
          <el-pagination
            @size-change="handleSearch"
            @current-change="handleSearch"
            :current-page.sync="query.pageIndex"
            :page-size.sync="query.pageSize"
            :pager-count="5"
            layout="total, prev, pager, next"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import HomeSwiper from "@/components/Cms/HomeSwiper";
import { searchGoods } from "@/api/cms/goods.js";

export default {
  components: {
    HomeSwiper,
  },
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 16,
        keyword: "",
      },
      total: 0,
      tableData: [],
      loading: false,
    };
  },
  methods: {
    handleClick(id) {
      window.open(`/cms/goods/show/${id}`);
    },
    search(searchText) {
      this.query.keyword = searchText;
      this.searchGoods();
    },
    handleSearch() {
      this.searchGoods();
    },
    searchGoods() {
      this.loading = true;
      searchGoods(this.query).then((res) => {
        this.loading = false;
        this.tableData = res.datas;
        this.total = res.totalRecords;
      });
    },
    handleCompany(id) {
      window.open(`/cms/company/show/${id}`);
    },
  },
  created() {
    if (this.$route.query.keyword) {
      this.query.keyword = this.$route.query.keyword;
    }
    this.searchGoods();
  },
};
</script>

<style scoped lang="scss">
.box-card {
  padding: 20px;
  max-width: 1280px;
  margin: auto;
}

.good-box {
  cursor: pointer;
  border: 1px solid #ededed;
  padding: 10px;

  .image-box {
    width: 100%;
    display: block;
    height: 200px;
    background-color: #fafafa;
    overflow: hidden;
  }

  .txt {
    padding: 10px;
    font-size: 26px;
    font-weight: 300;
  }

  .image {
    width: 100%;
    height: 100%;
  }

  .bottom {
    margin-top: 10px;
    color: #3d3d3d;
    height: 100px;
    overflow: hidden;

    .price {
      .num {
        color: #f40;
        font-weight: 700;
      }

      .norms {
        font-size: 12px;
        margin-left: 20px;
      }
    }

    .name {
      margin-top: 5px;
      font-size: 13px;
      height: 35px;
      overflow: hidden;
    }
    
    .tip {
      margin-top: 5px;
      font-size: 12px;
    }

    .company {
      margin-top: 3px;
      font-size: 12px;
      overflow: hidden;
    }
  }
}

.good-box:hover {
  border-color: #f40;
}

.page {
  text-align: center;
}

h2 {
  font-size: 40px;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 40px;
}

@media screen and (max-width: 700px) {
  .box-card {
    padding: 8px;
  }
}
</style>
